<template>
    <div class="nevs-content">
        <ImagePreview @close="closeImagePreview" :show="imagePreview.show" :url="imagePreview.url" :alt="imagePreview.alt"></ImagePreview>
        <div v-if="popup.show" class="nevs-popup-container">
            <div class="nevs-popup">
                <div class="popup-form">
                    <NevsTextField v-model="popup.data.title" :error="popup.validation.title"
                                   :label="'*' + $LANG.Get('fields.title') + ':'"></NevsTextField>
                    <NevsTextArea v-model="popup.data.content" :error="popup.validation.content"
                                  :label="'*' + $LANG.Get('fields.content') + ':'"></NevsTextArea>
                    <NevsUpload :accept="'.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.txt'" :label="$LANG.Get('fields.uploads') + ':'" v-model="popup.data.temporaryFile"></NevsUpload>
                    <div class="popup-form-uploads">
                        <div v-for="(upload, key) in popup.data.uploads" :key="key">
                            <span class="popup-form-uploads-delete-button" @click="popup.removeUpload(key)"><i class="fa-solid fa-trash"></i></span>&nbsp;
                            <a target="_blank" :href="upload.link">{{ upload.name }}</a>
                        </div>
                    </div>
                </div>
                <div class="nevs-popup-buttons">
                    <NevsButton class="error" @click="popup.cancelClick()">
                        <i class="fa-solid fa-times"></i>
                        {{ $LANG.Get('buttons.cancel') }}
                    </NevsButton>
                    <NevsButton class="success" @click="popup.saveClick()">
                        <i class="fa-solid fa-save"></i>
                        {{ $LANG.Get('buttons.save') }}
                    </NevsButton>&nbsp;
                </div>
            </div>
        </div>
        <div class="nevs-content-top-buttons" v-if="$store.state.user.permissions.includes('REPRESENTATIVE')">
            <NevsButton class="primary" @click="addClick">
                <i class="fa-solid fa-plus"></i>
                {{ $LANG.Get('buttons.add') }}
            </NevsButton>
        </div>
        <NevsCard v-for="(post, key) in posts" :key="key" class="dashboard-item">
            <div class="dashboard-item-title">
                {{ post.title }}
                <template v-if="$store.state.user.permissions.includes('REPRESENTATIVE')">
                    <span class="dashboard-item-button" @click="removePost(key)"><i class="fa-solid fa-trash"></i></span>
                    <span class="dashboard-item-button" @click="editPost(key)"><i class="fa-solid fa-pencil"></i></span>
                </template>
            </div>
            <div class="dashboard-item-content">
                <div v-html="post.content_display"></div>
                <div v-if="post.uploads.length > 0">
                    <br /><br />
                                        <div v-for="(upload, key) in post.uploads" :key="key">
                        <div :style="{ 'background-image' : 'url(' + upload.link + ')' }" @click="openImagePreview(upload)" class="upload-image" v-if="imageExtensions.includes(upload.name.split('.').pop())"></div>
                    </div>
                    <div class="nevs-clear-float" />
                    <div v-for="(upload, key) in post.uploads" :key="key">
                        <a v-if="!imageExtensions.includes(upload.name.split('.').pop())" :href="upload.link" target="_blank">{{ upload.name }}</a>
                    </div>
                </div>
            </div>
            <div class="dashboard-item-footer">
                {{ $LANG.Get('labels.created') + ': ' + post.user_created_display }}, {{ post.created_at_display }} <br />
                <span v-if="post.user_modified_id !== null">{{ $LANG.Get('labels.modified') + ': ' + post.user_modified_display }}, {{ post.modified_at_display }}</span>
            </div>
        </NevsCard>
    </div>
</template>

<script>
import ImagePreview from "@/components/general/ImagePreview";
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton.vue";
import NevsTextField from "@/components/nevs/NevsTextField.vue";
import NevsTextArea from "@/components/nevs/NevsTextArea.vue";
import NevsUpload from "@/components/nevs/NevsUpload.vue";

export default {
    name: "ModuleDashboard",
    components: {ImagePreview, NevsUpload, NevsTextArea, NevsTextField, NevsButton, NevsCard},
    data() {
        return {
            posts: [],
            imageExtensions: ['png', 'jpg', 'jpeg'],
            imagePreview: {
                show: false,
                url: null,
                alt: null
            },
            popup: {
                show: false,
                data: {
                    id: null,
                    title: '',
                    content: '',
                    temporaryFile: {
                        id: 0,
                        name: '',
                        link: ''
                    },
                    uploads: []
                },
                validation: {
                    title: '',
                    content: ''
                },
                saveClick: () => {
                    let valid = true;

                    if (this.popup.data.title == '') {
                        valid = false;
                        this.popup.validation.title = this.$LANG.Get('labels.mandatoryField');
                    } else {
                        this.popup.validation.title = '';
                    }

                    if (this.popup.data.content == '') {
                        valid = false;
                        this.popup.validation.content = this.$LANG.Get('labels.mandatoryField');
                    } else {
                        this.popup.validation.content = '';
                    }

                    if (valid) {
                        let vm = this;
                        let method = (this.popup.data.id === null) ? 'post' : 'put';
                        let service = (this.popup.data.id === null) ? 'dashboard-posts' : 'dashboard-posts/' + this.popup.data.id;

                        this.$API.APICall(method, service, this.popup.data, (data, success) => {
                            if (success) {
                                vm.popup.show = false;
                                vm.popup.reset();
                                vm.reloadData();
                            }
                        })
                    }
                },
                cancelClick: () => {
                    this.popup.show = false;
                    this.popup.reset();
                },
                reset: () => {
                    this.popup.data = {
                        id: null,
                        title: '',
                        content: '',
                        temporaryFile: {
                            id: 0,
                            name: '',
                            link: ''
                        },
                        uploads: []
                    }
                    this.popup.validation = {
                        title: '',
                        content: ''
                    }
                },
                removeUpload: (idx) => {
                                        let uploadsToKeep = [];
                    for (let uploadIdx = 0; uploadIdx < this.popup.data.uploads.length; uploadIdx++)
                    {
                        if (idx !== uploadIdx) {
                            uploadsToKeep.push(this.popup.data.uploads[uploadIdx]);
                        }
                    }
                    if (uploadsToKeep.length !== this.popup.data.uploads.length) {
                        this.popup.data.uploads.length = [];
                        for (let upload of uploadsToKeep) {
                            this.popup.data.uploads.push(upload);
                        }
                    }
                }
            },
        }
    },
    watch: {
        'popup.data.temporaryFile': {
            handler() {
                if (this.popup.data.temporaryFile.id === 0) return;
                this.popup.data.uploads.push(JSON.parse(JSON.stringify(this.popup.data.temporaryFile)));
                this.popup.data.temporaryFile = {
                    id: 0,
                    name: '',
                    link: ''
                };
            },
            deep: true
        }
    },
    methods: {
        closeImagePreview() {
            this.imagePreview.show = false;
            this.imagePreview.url = null;
            this.imagePreview.alt = null;
        },
        openImagePreview(file) {
            this.imagePreview.url = file.link;
            this.imagePreview.alt = file.name;
            this.imagePreview.show = true;
        },
        addClick() {
            this.popup.reset();
            this.popup.show = true;
        },
        reloadData() {
            let vm = this;
            this.$API.APICall('get', 'dashboard-posts', {}, (data, success) => {
                if (success) {
                    vm.posts = JSON.parse(JSON.stringify(data));
                }
            })
        },
        removePost(idx) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.recordDeletionQuestion'), callback: (response) => {
                    if (response) {
                        vm.$API.APICall('delete', 'dashboard-posts/' + vm.posts[idx].id, {}, () => {
                            vm.reloadData();
                        })
                    }
                }
            });
        },
        editPost(idx) {
            let vm = this;
            this.$API.APICall('get', 'dashboard-posts/' + this.posts[idx].id, {}, (data, success) => {
                if (success) {
                    vm.popup.data = {
                        id: data.post.id,
                        title: data.post.title,
                        content: data.post.content,
                        temporaryFile: {
                            id: 0,
                            name: '',
                            link: ''
                        },
                        uploads: data.post.uploads
                    };
                    vm.popup.show = true;
                }
            });
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'dashboard');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.dashboard'),
                link: null
            }
        ]);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);

        this.reloadData();
    }
}
</script>

<style scoped>
.dashboard-item {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.popup-form {
    padding: 10px;
}

>>> .popup-form textarea {
    min-height: 300px;
}

.popup-form-uploads {
    padding: 10px;
}

.popup-form-uploads-delete-button {
    cursor: pointer;
}

.dashboard-item-title {
    font-weight: bold;
    font-size: 17px;
    padding-left: 10px;
    padding-right: 10px;
}

.dashboard-item-content {
    font-size: 14px;
    border-top: 1px solid #d2d2d2;
    padding-left: 10px;
    padding-right: 10px;
    padding-top : 10px;
    text-align: justify;
}

.dashboard-item-footer {
    font-size: 12px;
    padding: 10px;
}

.dashboard-item-button {
    float: right;
    margin-left: 10px;
    cursor: pointer;
}

.upload-image {
    width: 100px;
    height: 100px;
    display: block;
    cursor: pointer;
    float: left;
    margin: 5px;
    background-size: cover;
}

.upload-image img {
    width: 100%;
}

@media only screen and (max-width: 1100px) {
    .dashboard-item {
        max-width: none;
    }
}
</style>