<template>
    <div v-if="sessionCheckDone" class="app-container">
        <NevsLoader v-if="$store.state.loaderCount > 0"></NevsLoader>
        <NevsNotification></NevsNotification>
        <NevsPopup></NevsPopup>
        <LoginForm v-if="$store.state.user === null" :postLogin="setupMenu"></LoginForm>
        <template v-if="$store.state.user !== null">
            <Transition name="main-menu">
                <NevsMainMenu v-show="showMenu" :items="menu.items" :logo="menu.logo"
                              @toggleMenu="showMenu=!showMenu"></NevsMainMenu>
            </Transition>
            <NevsTopBar :breadcrumbs="$store.state.breadcrumbs" :buttons="topBarButtons"
                        @toggleMenu="showMenu=!showMenu"></NevsTopBar>
            <div class="nevs-main-content">
                <div v-if="$store.state.user.client_status_id === 2" class="status-warning">
                    {{ $LANG.Get('alerts.accountWarned') }}
                </div>
                <RouterView></RouterView>
            </div>
        </template>
    </div>
</template>

<script>

import NevsLoader from "@/components/nevs/NevsLoader";
import NevsNotification from "@/components/nevs/NevsNotification";
import NevsPopup from "@/components/nevs/NevsPopup";
import LoginForm from "@/components/general/LoginForm";
import NevsMainMenu from "@/components/nevs/NevsMainMenu";
import NevsTopBar from "@/components/nevs/NevsTopBar";

export default {
    name: 'App',
    components: {
        NevsMainMenu,
        NevsTopBar,
        LoginForm,
        NevsPopup,
        NevsNotification,
        NevsLoader
    },
    data() {
        return {
            showMenu: true,
            sessionCheckDone: false,
            menu: {
                logo: process.env.BASE_URL + 'logo_inner.png',
                items: []
            },
            topBarButtons: [
                {
                    icon: '<i class="fa-solid fa-right-from-bracket"></i>',
                    tooltip: this.$LANG.Get('tooltips.logout'),
                    action: () => {
                        let vm = this;
                        this.$API.APICall('post', 'logout', {}, (data, success) => {
                            if (success) {
                                vm.$store.commit('setUser', null);
                            } else {
                                vm.$LOCAL_BUS.TriggerEvent('popup', {
                                    text: vm.$LANG.Get('alerts.serverError'),
                                    type: 'alert'
                                });
                            }
                        });
                    }
                },
                {
                    icon: '<i class="fa-solid fa-user"></i>',
                    tooltip: this.$LANG.Get('tooltips.myProfile'),
                    action: () => {
                        this.$router.push('/users/' + this.$store.state.user.id);
                    }
                }
            ]
        }
    },
    methods: {
        resolveWindowResize() {
            this.showMenu = window.innerWidth >= 800;
        },
        setupMenu() {
            this.menu.items = [];
            this.menu.items.push({
                id: 'dashboard',
                label: this.$LANG.Get('modules.dashboard'),
                link: '/',
                icon: '<i class="fa-solid fa-chalkboard"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'reports',
                label: this.$LANG.Get('modules.reports'),
                link: '/reports',
                icon: '<i class="fa-solid fa-triangle-exclamation"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'meetings',
                label: this.$LANG.Get('modules.meetings'),
                link: '/meetings',
                icon: '<i class="fa-solid fa-calendar"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'polls',
                label: this.$LANG.Get('modules.polls'),
                link: '/polls',
                icon: '<i class="fa-solid fa-square-poll-horizontal"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'neighbours',
                label: this.$LANG.Get('modules.neighbours'),
                link: '/neighbours',
                icon: '<i class="fa-solid fa-building"></i>',
                children: []
            });

            if (this.$store.state.user.permissions.includes('REPRESENTATIVE')) {
                this.menu.items.push({
                    id: '---'
                });
                this.menu.items.push({
                    id: 'users',
                    label: this.$LANG.Get('modules.users'),
                    link: '/users',
                    icon: '<i class="fa-solid fa-users"></i>',
                    children: []
                });
            }
            this.menu.items.push({
                id: '---'
            });
            this.menu.items.push({
                id: 'termsAndConditions',
                label: this.$LANG.Get('modules.termsAndConditions'),
                external: true,
                link: 'https://susjed42.com/uvjeti_koristenja.pdf',
                icon: '<i class="fa-solid fa-file-contract"></i>',
                children: []
            });
            this.menu.items.push({
                id: 'gdpr',
                label: this.$LANG.Get('modules.gdpr'),
                external: true,
                link: 'https://susjed42.com/GDPR.pdf',
                icon: '<i class="fa-solid fa-user-secret"></i>',
                children: []
            });
        }
    },
    mounted() {
        window.addEventListener('resize', this.resolveWindowResize);
        this.resolveWindowResize();
        let vm = this;
        this.$API.APICall('get', 'public/version', {}, (data, success) => {
            if (success) {
                if (data.version !== this.$HELPERS.GetCookie('susjed42_version')) {
                    this.$HELPERS.SetCookie('susjed42_version', data.version);
                    window.location.reload(true);
                }
            }
        });
        this.$API.APICall('get', 'session', {}, (data, success) => {
            if (success) {
                vm.$store.commit('setUser', data.user);
                vm.$store.commit('setLocale', data.user.locale);
                vm.$nextTick(() => {
                    this.setupMenu();
                });
            } else {
                vm.$store.commit('setLocale', vm.$store.state.settings.LOCALE);
            }
            vm.$nextTick(() => {
                vm.sessionCheckDone = true;
            });
        }, false);
        setInterval(() => {
            if (vm.$store.state.user !== null) {
                this.$API.APICall('get', 'public/heartbeat', {}, (data, success) => {
                    if (success) {
                        if (!data.logged_in) {
                            vm.$store.commit('setUser', null);
                        }
                        if (data.version !== this.$HELPERS.GetCookie('susjed42_version')) {
                            this.$HELPERS.SetCookie('susjed42_version', data.version);
                            window.location.reload(true);
                        }
                    }
                }, false);
            }
        }, 30000);
    }
}

</script>

<style scoped>
@media only screen and (max-width: 1920px) and (-webkit-device-pixel-ratio: 1.25) {
    >>> .nevs-main-menu {
        font-size: 16px;
    }
}

.status-warning {
    background: #F88379;
    padding: 10px;
    color: black;
}
</style>