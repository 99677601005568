<template>
    <div class="nevs-content">
        <ImagePreview @close="closeImagePreview" :show="imagePreview.show" :url="imagePreview.url" :alt="imagePreview.alt"></ImagePreview>
        <div v-if="popup.show" class="nevs-popup-container">
            <div class="nevs-popup">
                <div class="popup-form">
                    <NevsTextField v-model="popup.data.title" :error="popup.validation.title"
                                   :label="'*' + $LANG.Get('fields.title') + ':'"></NevsTextField>
                    <NevsTextArea v-model="popup.data.content" :error="popup.validation.content"
                                  :label="'*' + $LANG.Get('fields.content') + ':'"></NevsTextArea>
                    <div class="popup-form-options">
                        <div class="popup-form-options-label">
                            {{ $LANG.Get('fields.options') }}:<br />
                        </div>
                        <template v-for="(option, key) in popup.data.options" :key="key">
                            <NevsTextField v-model="popup.data.options[key]"></NevsTextField>
                        </template>
                        <NevsButton class="success" @click="popup.addOptionClick()">
                            <i class="fa-solid fa-plus"></i>
                            {{ $LANG.Get('buttons.addOption') }}
                        </NevsButton>
                    </div>
                    <NevsUpload :accept="'.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.txt'" :label="$LANG.Get('fields.uploads') + ':'" v-model="popup.data.temporaryFile"></NevsUpload>
                    <div class="popup-form-uploads">
                        <div v-for="(upload, key) in popup.data.uploads" :key="key">
                            <span class="popup-form-uploads-delete-button" @click="popup.removeUpload(key)"><i class="fa-solid fa-trash"></i></span>&nbsp;
                            <a target="_blank" :href="upload.link">{{ upload.name }}</a>
                        </div>
                    </div>
                </div>
                <div class="nevs-popup-buttons">
                    <NevsButton class="error" @click="popup.cancelClick()">
                        <i class="fa-solid fa-times"></i>
                        {{ $LANG.Get('buttons.cancel') }}
                    </NevsButton>
                    <NevsButton class="success" @click="popup.saveClick()">
                        <i class="fa-solid fa-save"></i>
                        {{ $LANG.Get('buttons.save') }}
                    </NevsButton>&nbsp;
                </div>
            </div>
        </div>
        <div class="nevs-content-top-buttons" v-if="$store.state.user.permissions.includes('REPRESENTATIVE')">
            <NevsButton class="primary" @click="addClick">
                <i class="fa-solid fa-plus"></i>
                {{ $LANG.Get('buttons.add') }}
            </NevsButton>
        </div>
        <NevsCard v-for="(poll, pollKey) in polls" :key="pollKey" class="poll">
            <div class="poll-title">
                {{ poll.title }}
                <template v-if="$store.state.user.permissions.includes('REPRESENTATIVE')">
                    <span class="poll-button" @click="removePost(pollKey)"><i class="fa-solid fa-trash"></i></span>
                    <span class="poll-button" @click="editPost(pollKey)"><i class="fa-solid fa-pencil"></i></span>
                </template>
            </div>
            <div class="poll-content">
                <div v-html="poll.content_display"></div>
                <div v-if="poll.uploads.length > 0">
                    <br /><br />
                    <div v-for="(upload, uploadKey) in poll.uploads" :key="uploadKey">
                        <div :style="{ 'background-image' : 'url(' + upload.link + ')' }" @click="openImagePreview(upload)" class="upload-image" v-if="imageExtensions.includes(upload.name.split('.').pop())"></div>
                    </div>
                    <div class="nevs-clear-float" />
                    <div v-for="(upload, uploadKey) in poll.uploads" :key="uploadKey">
                        <a v-if="!imageExtensions.includes(upload.name.split('.').pop())" :href="upload.link" target="_blank">{{ upload.name }}</a>
                    </div>
                </div>
            </div>
            <div class="poll-options">
                <template v-if="!poll.voted">
                    <div v-for="(option, optionKey) in poll.options" :key="optionKey" class="poll-option" @click="selectOption(pollKey, optionKey)">
                        <span v-if="poll.selected_option !== option" class="poll-option-selected"><i class="fa-regular fa-circle"></i></span>
                        <span v-if="poll.selected_option === option" class="poll-option-selected"><i class="fa-regular fa-circle-dot"></i></span>
                        <span class="poll-option-text">{{ option }}</span>
                    </div>
                    <NevsButton v-if="poll.selected_option !== null" class="success" @click="castVote(pollKey)">
                        {{ $LANG.Get('buttons.vote') }}
                    </NevsButton>
                </template>
                <template v-if="poll.voted">
                    <div v-for="(option, optionKey) in poll.options" :key="optionKey" class="poll-option" @click="showResults(pollKey, optionKey)">
                        <span class="poll-option-text">{{ option }} ({{ poll.results[option].count }})</span>
                    </div>
                </template>
            </div>
            <div class="poll-footer">
                {{ $LANG.Get('labels.created') + ': ' + poll.user_display }}, {{ poll.created_at_display }} <br />
            </div>
        </NevsCard>
    </div>
</template>

<script>
import ImagePreview from "@/components/general/ImagePreview";
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton.vue";
import NevsTextField from "@/components/nevs/NevsTextField.vue";
import NevsTextArea from "@/components/nevs/NevsTextArea.vue";
import NevsUpload from "@/components/nevs/NevsUpload.vue";

export default {
    name: "ModulePolls",
    components: {ImagePreview, NevsUpload, NevsTextArea, NevsTextField, NevsButton, NevsCard},
    data() {
        return {
            polls: [],
            imageExtensions: ['png', 'jpg', 'jpeg'],
            imagePreview: {
                show: false,
                url: null,
                alt: null
            },
            popup: {
                show: false,
                data: {
                    id: null,
                    title: '',
                    content: '',
                    temporaryFile: {
                        id: 0,
                        name: '',
                        link: ''
                    },
                    uploads: [],
                    options: []
                },
                validation: {
                    title: '',
                    content: ''
                },
                saveClick: () => {
                    let valid = true;

                    if (this.popup.data.title == '') {
                        valid = false;
                        this.popup.validation.title = this.$LANG.Get('labels.mandatoryField');
                    } else {
                        this.popup.validation.title = '';
                    }

                    if (this.popup.data.content == '') {
                        valid = false;
                        this.popup.validation.content = this.$LANG.Get('labels.mandatoryField');
                    } else {
                        this.popup.validation.content = '';
                    }

                    let optionsToKeep = [];
                    for (let optionIdx = 0; optionIdx < this.popup.data.options.length; optionIdx++)
                    {
                        this.popup.data.options[optionIdx] = this.popup.data.options[optionIdx].trim();
                        if (this.popup.data.options[optionIdx] !== '') {
                            optionsToKeep.push(this.popup.data.options[optionIdx]);
                        }
                    }
                    if (optionsToKeep.length !== this.popup.data.options.length) {
                        this.popup.data.options.length = [];
                        for (let option of optionsToKeep) {
                            this.popup.data.options.push(option);
                        }
                    }

                    if (valid) {
                        let vm = this;
                        let method = (this.popup.data.id === null) ? 'post' : 'put';
                        let service = (this.popup.data.id === null) ? 'polls' : 'polls/' + this.popup.data.id;

                        this.$API.APICall(method, service, this.popup.data, (data, success) => {
                            if (success) {
                                vm.popup.show = false;
                                vm.popup.reset();
                                vm.reloadData();
                            }
                        })
                    }
                },
                cancelClick: () => {
                    this.popup.show = false;
                    this.popup.reset();
                },
                reset: () => {
                    this.popup.data = {
                        id: null,
                        title: '',
                        content: '',
                        temporaryFile: {
                            id: 0,
                            name: '',
                            link: ''
                        },
                        uploads: [],
                        options: []
                    }
                    this.popup.validation = {
                        title: '',
                        content: ''
                    }
                },
                removeUpload: (idx) => {
                    let uploadsToKeep = [];
                    for (let uploadIdx = 0; uploadIdx < this.popup.data.uploads.length; uploadIdx++)
                    {
                        if (idx !== uploadIdx) {
                            uploadsToKeep.push(this.popup.data.uploads[uploadIdx]);
                        }
                    }
                    if (uploadsToKeep.length !== this.popup.data.uploads.length) {
                        this.popup.data.uploads.length = [];
                        for (let upload of uploadsToKeep) {
                            this.popup.data.uploads.push(upload);
                        }
                    }
                },
                addOptionClick: () => {
                    this.popup.data.options.push('');
                }
            },
        }
    },
    watch: {
        'popup.data.temporaryFile': {
            handler() {
                if (this.popup.data.temporaryFile.id === 0) return;
                this.popup.data.uploads.push(JSON.parse(JSON.stringify(this.popup.data.temporaryFile)));
                this.popup.data.temporaryFile = {
                    id: 0,
                    name: '',
                    link: ''
                };
            },
            deep: true
        }
    },
    methods: {
        showResults(pollKey, optionKey)
        {
            let poll = this.polls[pollKey];
            let results = poll.results;
            let option = poll.options[optionKey];
            let result = results[option];
            this.$LOCAL_BUS.TriggerEvent('popup', {
                text: result.display,
                type: 'alert'
            });
        },
        castVote(pollKey)
        {
            let poll = this.polls[pollKey];
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.pollVoteQuestion'), callback: (response) => {
                    if (response) {
                        vm.$API.APICall('post', 'polls/vote', {poll_id: poll.id, option: poll.selected_option}, () => {
                            vm.reloadData();
                        })
                    }
                }
            });
        },
        selectOption(pollKey, optionKey) {
            this.polls[pollKey].selected_option = this.polls[pollKey].options[optionKey];
        },
        closeImagePreview() {
            this.imagePreview.show = false;
            this.imagePreview.url = null;
            this.imagePreview.alt = null;
        },
        openImagePreview(file) {
            this.imagePreview.url = file.link;
            this.imagePreview.alt = file.name;
            this.imagePreview.show = true;
        },
        addClick() {
            this.popup.reset();
            this.popup.show = true;
        },
        reloadData() {
            let vm = this;
            this.$API.APICall('get', 'polls', {}, (data, success) => {
                if (success) {
                    vm.polls = JSON.parse(JSON.stringify(data));
                }
            })
        },
        removePost(idx) {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {
                type: 'confirm', text: this.$LANG.Get('alerts.recordDeletionQuestion'), callback: (response) => {
                    if (response) {
                        vm.$API.APICall('delete', 'polls/' + vm.polls[idx].id, {}, () => {
                            vm.reloadData();
                        })
                    }
                }
            });
        },
        editPost(idx) {
            let vm = this;
            this.$API.APICall('get', 'polls/' + this.polls[idx].id, {}, (data, success) => {
                if (success) {
                    vm.popup.data = {
                        id: data.poll.id,
                        title: data.poll.title,
                        content: data.poll.content,
                        temporaryFile: {
                            id: 0,
                            name: '',
                            link: ''
                        },
                        uploads: data.poll.uploads,
                        options: data.poll.options,
                    };
                    vm.popup.show = true;
                }
            });
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'polls');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.polls'),
                link: null
            }
        ]);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);

        this.reloadData();
    }
}
</script>

<style scoped>
.poll {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.popup-form {
    padding: 10px;
}

>>> .popup-form textarea {
    min-height: 300px;
}

.popup-form-uploads {
    padding: 10px;
}

.popup-form-uploads-delete-button {
    cursor: pointer;
}

.poll-title {
    font-weight: bold;
    font-size: 17px;
    padding-left: 10px;
    padding-right: 10px;
}

.poll-content {
    font-size: 14px;
    border-top: 1px solid #d2d2d2;
    padding-left: 10px;
    padding-right: 10px;
    padding-top : 10px;
    text-align: justify;
}

.poll-footer {
    font-size: 12px;
    padding: 10px;
}

.poll-button {
    float: right;
    margin-left: 10px;
    cursor: pointer;
}

.upload-image {
    width: 100px;
    height: 100px;
    display: block;
    cursor: pointer;
    float: left;
    margin: 5px;
    background-size: cover;
}

.upload-image img {
    width: 100%;
}

.popup-form-options {
    padding: 5px;
}

.popup-form-options-label {
    margin-bottom: 3px;
}

.poll-options {
    padding: 10px;
}

.poll-option {
    cursor: pointer;
    padding: 5px;
}

.poll-option:hover {
    background: #b2b2b2;
}

.poll-option-text {
    margin-left: 5px;
}

@media only screen and (max-width: 1100px) {
    .poll {
        max-width: none;
    }
}
</style>