<template>
    <div class="nevs-content">
        <NevsCard v-for="(entranceData, entrance) in neighbours" class="entrance" :key="entrance">
            <div class="entrance-name">{{ $LANG.Get('fields.entrance') }} {{ entrance }}</div>
            <div  v-for="(floorData, floor) in entranceData" :key="floor" class="floor">
                <div class="floor-name">{{ $LANG.Get('fields.floor') }} {{ floor }}</div>
                <ul>
                    <li v-for="(neighbour, key) in floorData" :key="key" class="neighbour">
                        <strong>{{ neighbour.first_name }}&nbsp;{{ neighbour.last_name }}</strong>
                        <span v-if="neighbour.mobile != ''">, {{ neighbour.mobile }}</span>
                        <span v-if="neighbour.email != ''">, {{ neighbour.email }}</span>
                    </li>
                </ul>
            </div>
        </NevsCard>
    </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
export default {
    name: "ModuleDashboard",
    components: {NevsCard},
    data() {
        return {
            neighbours: {}
        }
    },
    methods: {
        reloadData() {
            let vm = this;
            this.$API.APICall('get', 'users/neighbours', {}, (data, success) => {
                if (success) {
                    vm.neighbours = JSON.parse(JSON.stringify(data));
                }
            })
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'neighbours');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.neighbours'),
                link: null
            }
        ]);

        this.reloadData();
    }
}
</script>

<style scoped>
.entrance {
    max-width: 600px;
    padding-left: 10px;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}
.entrance-name {
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
}
.floor {
    padding-left: 10px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}
.neighbour {
    padding-left: 10px;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
}
</style>