<template>
    <div @click="closeClick" class="lightbox" v-if="show">
        <div :style="{ 'background-image' : 'url(' + url + ')' }" class="lightbox-image" />
    </div>
</template>

<script>

export default {
    name: "ImagePreview",
    props: {
        url: String,
        alt: String,
        show: Boolean
    },
    emits: [
        'close'
    ],
    methods: {
        closeClick() {
            this.$emit('close');
        }
    }
}

</script>

<style scoped>
    .lightbox {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        z-index: 99999999;
        text-align: center;
        padding-top: 100px;
    }

    .lightbox-image {
        width: calc(100vw - 100px);
        height: calc(100vh - 200px);
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
</style>