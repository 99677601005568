<template>
    <div v-if="loaded" class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
            <NevsButton class="error" @click="backClick"><i class="fa-solid fa-arrow-left"></i> {{
                    $LANG.Get('buttons.back')
                }}
            </NevsButton>
        </div>
        <NevsCard class="form">
            <NevsTextField v-if="mode === 'edit'" readonly v-model="user.username" :error="userValidation.username"
                           :label="$LANG.Get('fields.username') + ':'"></NevsTextField>
            <NevsTextField v-model="user.first_name" :error="userValidation.first_name"
                           :label="'*' + $LANG.Get('fields.firstName') + ':'"></NevsTextField>
            <NevsTextField v-model="user.last_name" :error="userValidation.last_name"
                           :label="'*' + $LANG.Get('fields.lastName') + ':'"></NevsTextField>
            <NevsTextField v-model="user.mobile" :error="userValidation.mobile"
                           :label="$LANG.Get('fields.mobile') + ':'"></NevsTextField>
            <NevsTextField v-model="user.email" :error="userValidation.email"
                           :label="$LANG.Get('fields.email') + ':'"></NevsTextField>
            <NevsCheckbox v-model="user.send_email_notifications" :label="$LANG.Get('fields.sendEmailNotifications')"></NevsCheckbox>
            <template v-if="$store.state.user.permissions.includes('REPRESENTATIVE')">
                <NevsMultipleSelect v-model="user.permissions" :ajax="'select/permissions'"
                                    :label="$LANG.Get('fields.permissions') + ':'"></NevsMultipleSelect>
                <NevsCheckbox v-model="user.active" :label="$LANG.Get('fields.activeM')"></NevsCheckbox>
            </template>
            <NevsCheckbox v-if="mode === 'edit'" v-model="changePassword"
                          :label="$LANG.Get('labels.changePassword')"></NevsCheckbox>
            <NevsTextField v-if="changePassword"
                           v-model="user.password" :error="userValidation.password"
                           :label="'*' + $LANG.Get('fields.password') + ':'"></NevsTextField>
        </NevsCard>
        <NevsCard class="ownership">
            <table>
                <tr>
                    <th>
                        {{ $LANG.Get('fields.entrance') }}
                    </th>
                    <th>
                        {{ $LANG.Get('fields.floor') }}
                    </th>
                    <th></th>
                </tr>
                <tr v-for="(data, key) in user.ownership" :key="key">
                    <td>
                        <NevsTextField v-model="data.entrance" :error="userValidation.entrance"></NevsTextField>
                    </td>
                    <td>
                        <NevsTextField v-model="data.floor" :error="userValidation.floor"></NevsTextField>
                    </td>
                    <td><nevs-button @click="removeOwnership(key)"><i class="fa-solid fa-trash"></i></nevs-button></td>
                </tr>
                <tr>
                    <td colspan="3">
                        <nevs-button @click="addOwnership">{{ $LANG.Get('buttons.addApartment') }}</nevs-button>
                    </td>
                </tr>
            </table>
            <span v-if="(userValidation.ownership!== '')" class="nevs-field-error">{{ userValidation.ownership }}</span>
        </NevsCard>
    </div>
</template>

<script>
import User from '@/models/User';
import NevsCard from "@/components/nevs/NevsCard";
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";
import NevsButton from "@/components/nevs/NevsButton";
import NevsMultipleSelect from "@/components/nevs/NevsMultipleSelect";

export default {
    name: "EntityUser",
    components: {NevsMultipleSelect, NevsButton, NevsCheckbox, NevsTextField, NevsCard},
    data() {
        return {
            loaded: false,
            mode: this.$route.params.id === '0' ? 'add' : 'edit',
            user: new User({id: this.$route.params.id}),
            userValidation: new User({}, true),
            changePassword: false
        }
    },
    methods: {
        removeOwnership(key)
        {
            this.user.ownership = this.user.ownership.splice(key, -1);
        },
        addOwnership() {
            this.user.ownership.push({
                entrance: '',
                floor: ''
            });
        },
        backClick() {
            if (window.history.length === 1) {
                window.close();
            } else {
                this.$router.back();
            }
        },
        saveClick() {
            let valid = true;

            if (this.user.first_name === '') {
                valid = false;
                this.userValidation.first_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.userValidation.first_name = '';
            }

            if (this.user.last_name === '') {
                valid = false;
                this.userValidation.last_name = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.userValidation.last_name = '';
            }

            if (this.user.apartment === '') {
                valid = false;
                this.userValidation.apartment = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.userValidation.apartment = '';
            }

            if (this.user.email !== '' && !this.$HELPERS.CheckEmail(this.user.email)) {
                valid = false;
                this.userValidation.email = this.$LANG.Get('labels.invalidEmailAddress');
            } else {
                this.userValidation.email = '';
            }

            if (this.mode === 'edit' && !this.changePassword) {
                this.user.password = '';
            }

            if (this.changePassword) {
                if (this.user.password === '') {
                    valid = false;
                    this.userValidation.password = this.$LANG.Get('labels.mandatoryField');
                } else {
                    this.userValidation.password = '';
                }
            }

            if (this.user.ownership.length == 0) {
                valid = false;
                this.userValidation.ownership = this.$LANG.Get('labels.mandatoryField');
            } else {
                this.userValidation.ownership = '';
            }

            if (valid) {
                let vm = this;
                let requestMethod = this.mode === 'add' ? 'post' : 'put';
                let requestService = this.mode === 'add' ? 'users' : 'users/' + this.user.id;
                this.$API.APICall(requestMethod, requestService, this.user, (data, success) => {
                    if (success) {
                        vm.$CROSS_TAB_BUS.TriggerEvent('reload-users', {});
                        vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                        vm.$API.APICall('get', 'session', {}, (data, success) => {
                            if (success) {
                                vm.$store.commit('setUser', data.user);
                                vm.backClick();
                            }
                        }, false);
                        if (vm.mode === 'add') {
                            let message = vm.$LANG.Get('alerts.userAdded');
                            message = message.split('{username}').join(data.user.username);
                            message = message.split('{password}').join(data.password);
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: message,
                                type: 'alert'
                            });
                        }
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                            text: vm.$LANG.Get('alerts.serverError'),
                            type: 'alert'
                        });
                    }
                })
            }
        }
    },
    mounted() {
        if (!this.$store.state.user.permissions.includes('REPRESENTATIVE')) {
            if (this.$store.state.user.id != this.user.id) {
                this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
                this.backClick();
            }
        }

        this.$store.commit('selectMenu', 'settings');
        this.$store.commit('selectSubMenu', 'users');

        if (this.mode === 'add') {
            if (!this.$store.state.user.permissions.includes('REPRESENTATIVE')) {
                this.$LOCAL_BUS.TriggerEvent('popup', {text: this.$LANG.Get('alerts.unauthorized'), type: 'alert'});
                this.backClick();
            }
            this.$store.commit('setBreadcrumbs', [
                {
                    label: this.$LANG.Get('modules.settings'),
                    link: null
                },
                {
                    label: this.$LANG.Get('modules.users'),
                    link: '/users'
                },
                {
                    label: this.$LANG.Get('modules.newUser'),
                    link: null
                }
            ]);
            this.loaded = true;
        } else {
            let vm = this;
            this.$API.APICall('get', 'users/' + this.user.id, {}, (data, success) => {
                if (success) {
                    if (data.user !== null) {
                        vm.user.Fill(data.user);
                        vm.$store.commit('setBreadcrumbs', [
                            {
                                label: this.$LANG.Get('modules.settings'),
                                link: null
                            },
                            {
                                label: vm.$LANG.Get('modules.users'),
                                link: '/users'
                            },
                            {
                                label: vm.user.first_name + ' ' + vm.user.last_name,
                                link: null
                            }
                        ]);
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                    }
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
                vm.$nextTick(() => {
                    vm.loaded = true;
                });
            });
        }
    }
}
</script>

<style scoped>
.form {
    max-width: 300px;
    float: left;
}
.ownership {
    width: 300px;
    float: left;
    margin-left: 20px;
}
.ownership table {
    width: 100%;
}
@media only screen and (max-width: 929px) {
    .form {
        max-width: none;
    }
    .ownership {
        width: calc(100% - 20px);
        max-width: none;
        margin-top: 20px;
        margin-left: 0px;
    }
}
</style>