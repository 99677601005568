import ModuleDashboard from "@/components/modules/ModuleDashboard";
import ModuleUsers from "@/components/modules/ModuleUsers";
import EntityUser from "@/components/entities/EntityUser";
import ModuleReports from "@/components/modules/ModuleReports.vue";
import EntityReport from "@/components/modules/EntityReport.vue";
import ModuleNeighbours from "@/components/modules/ModuleNeighbours.vue";
import ModuleMeetings from "@/components/modules/ModuleMeetings.vue";
import ModulePolls from "@/components/modules/ModulePolls.vue";

export default [
    { path: '/', component: ModuleDashboard },
    { path: '/users', component: ModuleUsers,  meta: { transition: 'slide-left' } },
    { path: '/users/:id', component: EntityUser },
    { path: '/reports', component: ModuleReports },
    { path: '/reports/:id', component: EntityReport },
    { path: '/neighbours', component: ModuleNeighbours },
    { path: '/meetings', component: ModuleMeetings },
    { path: '/polls', component: ModulePolls },
]