<template>
    <NevsCard
        class="login-card">
        <div class="logo-container">
            <img :src="logo"/>
        </div>
        <template v-if="mode==='login'">
            <NevsTextField v-model="loginData.username" :label="$LANG.Get('fields.username')+':'" :name="'username'"
                           :width="'100%'"></NevsTextField>
            <NevsTextField v-model="loginData.password" :label="$LANG.Get('fields.password')+':'" :name="'password'"
                           :type="'password'" :width="'100%'"
                           @keyup.enter="loginClick()"></NevsTextField>
            <NevsCheckbox v-model="loginData.remember" :label="$LANG.Get('labels.rememberLogin')"></NevsCheckbox>
            <div class="login-card-footer">
                <NevsButton class="nevs-float-right" @click="loginClick">{{ $LANG.Get('buttons.login') }}</NevsButton>
            </div>
        </template>
        <template v-if="mode==='temporaryPasswordChange'">
            <div class="temporary-password-disclaimer" v-html="$LANG.Get('labels.temporaryPasswordDisclaimer')"></div>
            <NevsTextField v-model="loginData.password" :label="$LANG.Get('fields.newPassword')+':'" :name="'password'"
                           :type="'text'" :width="'100%'"
                           @keyup.enter="temporaryPasswordChangeClick"></NevsTextField>
            <div class="login-card-footer">
                <NevsButton class="nevs-float-right" @click="temporaryPasswordChangeClick">{{ $LANG.Get('buttons.saveNewPassword') }}</NevsButton>
            </div>
        </template>
        <template v-if="mode==='termsAndConditions'">
            <div class="temporary-password-disclaimer" v-html="$LANG.Get('labels.termsAndConditionsDisclaimer')"></div>
            <NevsCheckbox v-model="loginData.terms_and_conditions" :label="$LANG.Get('fields.termsAndConditionsAccept')"></NevsCheckbox>
            <div class="login-card-footer">
                <NevsButton class="nevs-float-right" @click="loginClick">{{ $LANG.Get('buttons.accept') }}</NevsButton>
            </div>
        </template>
        <div class="nevs-clear-float"></div>
        <div class="login-disclaimer" v-html="$LANG.Get('labels.loginFooter')"></div>
    </NevsCard>
</template>

<script>
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import NevsCheckbox from "@/components/nevs/NevsCheckbox";

export default {
    name: "LoginForm",
    components: {
        NevsCheckbox,
        NevsTextField,
        NevsCard,
        NevsButton
    },
    props: {
        postLogin: {
            type: Function
        }
    },
    data() {
        return {
            mode: 'login',
            temporaryPasswordHash: null,
            logo: process.env.BASE_URL + 'logo.png',
            loginData: {
                username: '',
                password: '',
                remember: false,
                terms_and_conditions: false
            }
        }
    },
    methods: {
        temporaryPasswordChangeClick() {
            if (this.temporaryPasswordHash === null) return;
            let vm = this;
            let payload = {
                password: this.loginData.password,
                hash: this.temporaryPasswordHash
            }
            this.$API.APICall('post', 'public/change-temporary-password', payload, (data, success) => {
                if (success) {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {
                        text: vm.$LANG.Get('alerts.temporaryPasswordChanged'),
                        type: 'alert'
                    });
                    vm.temporaryPasswordHash = null;
                    vm.loginData.username = '';
                    vm.loginData.password = '';
                    vm.mode = 'login';
                }
            });
        },
        loginClick() {
            let vm = this;
            this.$API.APICall('post', 'public/login', this.loginData, (data, success) => {
                if (success) {
                    vm.$store.commit('setUser', data.user);
                    vm.$store.commit('setLocale', data.user.locale);
                    vm.postLogin();
                } else {
                    if (data.error !== undefined) {
                        if (data.error === 'account blocked') {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.accountBlocked'),
                                type: 'alert'
                            });
                        } else if (data.error === 'temporary password') {
                            vm.temporaryPasswordHash = data.hash;
                            vm.loginData.username = '';
                            vm.loginData.password = '';
                            vm.mode = 'temporaryPasswordChange';
                        } else if (data.error === 'terms and conditions version does not match') {
                            vm.terms_and_conditions = false;
                            vm.mode='termsAndConditions';
                        } else {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.loginFailed'),
                                type: 'alert'
                            });
                        }
                    } else {
                        vm.$LOCAL_BUS.TriggerEvent('popup', {
                            text: vm.$LANG.Get('alerts.serverError'),
                            type: 'alert'
                        });
                    }
                }
            });
        },
    }
}
</script>

<style scoped>
.logo-container {
    text-align: center;
    border-bottom: 1px dotted #5e6278;
    margin-bottom: 10px;
}

.logo-container img {
    width: 100%;
    max-width: 250px;
}

.login-disclaimer {
    text-align: center;
    margin-top: 20px;
    border-top: 1px dotted #5e6278;
    padding-top: 5px;
}

.temporary-password-disclaimer {
    text-align: justify;
    margin-bottom: 10px;
}

</style>